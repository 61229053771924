import React, { useEffect, useState, useCallback } from "react";
import { styles } from "./styles";
import { OnestBoldSmall, OnestNormalDefault, OnestNormalSmall, OnestNormalBig } from "../../../styled/TextComponents";

import { StudentScheduleBlock } from "../../StudentSchedule";
import minutesToStr from "../../../../utilities/munutesToStr";

const weekDayNames = {
    "ПН": "Понедельник",
    "ВТ": "Вторник",
    "СР": "Среда",
    "ЧТ": "Четверг",
    "ПТ": "Пятница",
    "СБ": "Суббота",
    "ВС": "Воскресенье"
};

export const WeekdayTimeSelector = ({ selectedWeekdays, selectedTimes, onTimeChange }) => {

    const handleTimeChange = useCallback((weekday, time) => {
        onTimeChange(weekday, time);
    }, [onTimeChange]);

    const countWeekdayChange = (startHour, startMins, endHour, endMinute, quantityStr) => {
        const quantity = parseInt(quantityStr.charAt(0), 10)
        console.warn("quantity", quantity)
        console.warn("startHour", startHour)
        console.warn("startMins", startMins)
        console.warn("endHour", endHour)
        console.warn("endMinute", endMinute)

        let newEndHour = endHour;
        let newEndMinute = endMinute;

        if (+quantity === 1) {
            if (+startMins < 30) {
                newEndHour = +startHour;
                newEndMinute = +startMins + 30;
            } else {
                newEndHour = +startHour + 1;
                newEndMinute = +startMins - 30;
            }
        }

        if (+quantity === 2) {
            newEndHour = +startHour + 1;
            newEndMinute = +startMins;
        }

        if (+quantity === 3) {
            if (+startMins < 30) {
                newEndHour = +startHour + 1;
                newEndMinute = +startMins + 30;
            } else {
                newEndHour = +startHour + 2;
                newEndMinute = +startMins - 30;
            }
        }

        if (+quantity === 4) {
            newEndHour = +startHour + 2;
            newEndMinute = +startMins;
        }

        
        console.warn("quantity", quantity)
        console.warn("startHour", startHour)
        console.warn("startMins", startMins)
        console.warn("newEndHour", newEndHour)
        console.warn("newEndMinute", newEndMinute)

        return [newEndHour, newEndMinute];
    }

    //console.warn("selectedWeekdays", selectedWeekdays)
    //console.warn("selectedTimes", selectedTimes)\

    /* 
    const timeSlots = [...Array(23).keys()].map(el => {
        return [`${`${el}`.padStart(2, "0")}:00 - ${`${el}`.padStart(2, "0")}:30`, `${`${el}`.padStart(2, "0")}:30 - ${`${el + 1}`.padStart(2, "0")}:00`];
    }).flat();
    */
    return (
        <div style={{ ...styles.timeSelectContainer }}>
            {selectedWeekdays === 0 ?

                <OnestNormalBig>Расписание не назначено</OnestNormalBig>
                :
                <>
                    <OnestBoldSmall>Время занятия</OnestBoldSmall>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 10
                    }}>
                        {selectedWeekdays.map(weekday => {

                            return (
                                <div style={{ ...styles.infoEditContainer }} key={weekday}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        gap: 10,
                                    }}>
                                        <StudentScheduleBlock text={weekDayNames[weekday]} />
                                        {/* 
                                        <StudentScheduleBlock text="с" />
                                         */}
                                        <select
                                            value={selectedTimes[weekday]?.split(" - ")[0].split(":")[0] || ""}
                                            onChange={e => {
                                                const [start, end, quantity] = selectedTimes[weekday]?.split(" - ") || ["", "", 1];
                                                let startHour = +e.target.value;
                                                let startMins = +start.split(":")[1] ? start.split(":")[1] : "00";
                                                let endHour = +end.split(":")[0];
                                                let endMinute = +end.split(":")[1];
/*
                                                if (+startMins < 30) {
                                                    endHour = +e.target.value;
                                                    endMinute = +startMins + 30;
                                                } else {
                                                    endHour = +e.target.value + 1;
                                                    endMinute = +startMins - 30;
                                                }
*/     
                                                const [newEndHour, newEndMin] = countWeekdayChange(startHour, startMins, endHour, endMinute, quantity);

                                                const newStart = `${e.target.value}:${minutesToStr(startMins)}`;
                                                const newEnd = `${minutesToStr(newEndHour)}:${minutesToStr(newEndMin)}`;
                                                handleTimeChange(weekday, `${newStart} - ${newEnd} - ${quantity}`);
                                            }}
                                            style={{ ...styles.timeSelect }}
                                        >
                                            <option value="" disabled>часы</option>
                                            {[...Array(24).keys()].map(el => (
                                                <option
                                                    key={el}
                                                    value={el.toString().padStart(2, "0")}
                                                    style={{ ...styles.timeSelectOption }}>
                                                    {el.toString().padStart(2, "0")}
                                                </option>
                                            ))}
                                        </select>
                                        :
                                        <select
                                            value={selectedTimes[weekday]?.split(" - ")[0].split(":")[1] || ""}
                                            onChange={e => {
                                                const [start, end, quantity] = selectedTimes[weekday]?.split(" - ") || ["", "", 1];
                                                
                                                let startHour = start.split(":")[0] !== "" ? start.split(":")[0] : "00";
                                                let startMins = +e.target.value;
                                                let endHour = +end.split(":")[0];
                                                let endMinute = +end.split(":")[1];
/*
                                                if (+e.target.value < 30) {
                                                    endHour = +start.split(":")[0];
                                                    endMinute = +e.target.value + 30;
                                                } else {
                                                    endHour = +start.split(":")[0] + 1;
                                                    endMinute = +e.target.value - 30;
                                                }
*/     
                                                const [newEndHour, newEndMin] = countWeekdayChange(startHour, startMins, endHour, endMinute, quantity);

                                                const newStart = `${minutesToStr(startHour)}:${e.target.value}`;
                                                const newEnd = `${minutesToStr(newEndHour)}:${minutesToStr(newEndMin)}`;
                                                handleTimeChange(weekday, `${newStart} - ${newEnd} - ${quantity}`);
                                            }}
                                            style={{ ...styles.timeSelect }}
                                        >
                                            <option value="" disabled>минуты</option>
                                            {["00", "10", "20", "30", "40", "50"].map(minute => (
                                                <option
                                                    key={minute}
                                                    value={minute}
                                                    style={{ ...styles.timeSelectOption }}>
                                                    {minute}
                                                </option>
                                            ))}
                                        </select>
                                        {/* 
                                        <StudentScheduleBlock text="до" />
                                        <select
                                            value={selectedTimes[weekday]?.split(" - ")[1].split(":")[0] || ""}
                                            onChange={e => {
                                                const [start, end] = selectedTimes[weekday]?.split(" - ") || ["", ""];

                                                let startHour = start.split(":")[0]?.length === 2 ? start.split(":")[0] : "18";
                                                let startMins = start.split(":")[1]?.length === 2 ? start.split(":")[1] : "00";
                                                
                                                let endMins = end.split(":")[1]?.length === 2 ? end.split(":")[1] : "00";

                                                if (+endMins < 30) {
                                                    startHour = +e.target.value - 1;
                                                    startMins = +endMins+30;
                                                }else{
                                                    startHour = +e.target.value;
                                                    startMins = +endMins-30;
                                                }

                                                const newStart = `${minutesToStr(startHour)}:${minutesToStr(startMins)}`;
                                                const newEnd = `${e.target.value}:${minutesToStr(endMins)}`;

                                                handleTimeChange(weekday, `${newStart} - ${newEnd}`);
                                            }}
                                            style={{ ...styles.timeSelect }}
                                        >
                                            <option value="" disabled>часы</option>
                                            {timeSlots.map(el => (
                                                <option key={el} value={el.split(" - ")[1].split(":")[0]} style={{ ...styles.timeSelectOption }}>{el.split(" - ")[1].split(":")[0]}</option>
                                            ))}
                                        </select>
                                        <select
                                            value={selectedTimes[weekday]?.split(" - ")[1].split(":")[1] || ""}
                                            onChange={e => {
                                                const [start, end] = selectedTimes[weekday]?.split(" - ") || ["", ""];

                                                
                                                let startHour = start.split(":")[0] !== "" ? start.split(":")[0] : "18";
                                                let startMins = start.split(":")[1] !== "" ? start.split(":")[1] : "00";
                                                
                                                let endHour = end.split(":")[0] !== "" ? end.split(":")[1] : "19";

                                                if (+e.target.value < 30) {
                                                    startHour = +endHour - 1;
                                                    startMins = +e.target.value+30;
                                                }else{
                                                    startHour = +endHour;
                                                    startMins = +e.target.value-30;
                                                }

                                                const newStart = `${minutesToStr(startHour)}:${minutesToStr(startMins)}`;

                                                const newEnd = `${endHour}:${e.target.value}`;
                                                handleTimeChange(weekday, `${newStart} - ${newEnd}`);
                                            }}
                                            style={{ ...styles.timeSelect }}
                                        >
                                            <option value="" disabled>минуты</option>
                                            {["00", "15", "30", "45"].map(minute => (
                                                <option key={minute} value={minute} style={{ ...styles.timeSelectOption }}>{minute}</option>
                                            ))}
                                        </select>
                                         */}
                                        <select
                                            value={parseInt(selectedTimes[weekday]?.split(" - ")[2].charAt(0), 10) || 1}
                                            onChange={e => {
                                                const [start, end, quantity] = selectedTimes[weekday]?.split(" - ") || ["", "", 1];
                                                let startHour = start.split(":")[0] !== "" ? start.split(":")[0] : "00";
                                                let startMins = start.split(":")[1] ? start.split(":")[1] : "00";
                                                let endHour = +end.split(":")[0];
                                                let endMinute = +end.split(":")[1];

                                                if (+e.target.value === 1) {
                                                    if (+startMins < 30) {
                                                        endHour = +startHour;
                                                        endMinute = +startMins + 30;
                                                    } else {
                                                        endHour = +startHour + 1;
                                                        endMinute = +startMins - 30;
                                                    }
                                                }

                                                if (+e.target.value === 2) {
                                                    endHour = +startHour + 1;
                                                    endMinute = +startMins;
                                                }

                                                if (+e.target.value === 3) {
                                                    if (+startMins < 30) {
                                                        endHour = +startHour + 1;
                                                        endMinute = +startMins + 30;
                                                    } else {
                                                        endHour = +startHour + 2;
                                                        endMinute = +startMins - 30;
                                                    }
                                                }

                                                if (+e.target.value === 4) {
                                                    endHour = +startHour + 2;
                                                    endMinute = +startMins;
                                                }

                                                const newStart = start;
                                                const newEnd = `${minutesToStr(endHour)}:${minutesToStr(endMinute)}`;
                                                handleTimeChange(weekday, `${newStart} - ${newEnd} - ${e.target.value}`);

                                            }}
                                            style={{ ...styles.timeSelect, width: 150 }}
                                        >
                                            {[1, 2, 3, 4].map(el => (
                                                <option
                                                    key={el}
                                                    value={el}
                                                    style={{ ...styles.timeSelectOption }}>
                                                    {"кол-во: " + el}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </>
            }
        </div>
    );
};
