import React, { useEffect, useState, useCallback, useMemo } from "react"
import { BsCheck2, BsXLg } from "react-icons/bs"
import { LuLoader } from "react-icons/lu";

import {
    color_green,
    color_green_light,
    color_grey_dark,
    color_grey_light,
    color_grey_ultra_light,
    color_transparent,
    color_white
} from "../../constants/colors"
import { OnestBoldDefault, OnestNormalDefault } from "../styled/TextComponents"
import { BlockTitle } from "../text/BlockTitle"
import { Button } from "../controls/Button/Button"
import { useDispatch, useSelector } from "react-redux";
import { updateStudent } from "../../store/StudentSlice";
import axios from "axios"
import Config from "../../Config"
import { useNavigate } from "react-router-dom";
import ModalQuestion from "./ModalQuestion";

const StudentLessonText = ({ index, title, level }) => {
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            gap: 5
        }}>
            <OnestBoldDefault>{index + ". " + title}</OnestBoldDefault>
            <OnestNormalDefault>{level}</OnestNormalDefault>
        </div>
    )
}

const StudentLesson = ({ lessonData, previousIsDone, isLast, levels }) => {
    const student = useSelector(state => state.student);
    const dispatch = useDispatch();
    const [isModalQuestionOpen, setIsModalQuestionOpen] = useState(false);
    const handleOpenQuestion = useCallback(() => {
        setIsModalQuestionOpen(true);
    }, []);

    const handleDelete = useCallback(async (isDelete) => {

       if (isDelete) {
            axios.patch(Config.BACKEND_ADDR + `/program/step/delete/`, {

                id: lessonData.id,
                student_id: lessonData.student_id,
                level_id: lessonData.level_id,
                material_id: lessonData.material_id,
                is_deleted: !lessonData.is_deleted
            }).then(res => {

                const createdAt = new Date(res.data.created_at).getTime();
                const updatedAt = new Date(res.data.updated_at).getTime();
                const deletedAt = res.data.deleted_at ? new Date(res.data.deleted_at).getTime() : null;

                let resultUT = {
                    ...res.data,
                    created_at: createdAt,
                    updated_at: updatedAt,
                    deleted_at: deletedAt
                };
                let newData = [];


                for (const lesson of student.lessonsProgram) {
                    if (+lesson.id === +res.data.id) {
                        newData = [...newData, resultUT];
                    } else {
                        newData = [...newData, lesson];
                    }
                }


                dispatch(updateStudent({
                    "lessonsProgram": newData
                }))
            })
            .catch(err => { console.warn(err) })
        }

        setIsModalQuestionOpen(false)
    }, [student, lessonData, dispatch]);

    const handleToggleComplete = useCallback(async () => {
        try {
            const res = await axios.patch(Config.BACKEND_ADDR + `/program/step/done/`, {
                id: lessonData.id,
                student_id: lessonData.student_id,
                level_id: lessonData.level_id,
                material_id: lessonData.material_id,
                is_done: !lessonData.is_done
            });

            if (!isLast || lessonData.is_done) {
                const createdAt = new Date(res.data.created_at).getTime();
                const updatedAt = new Date(res.data.updated_at).getTime();
                const deletedAt = res.data.deleted_at ? new Date(res.data.deleted_at).getTime() : null;

                let resultUT = {
                    ...res.data,
                    created_at: createdAt,
                    updated_at: updatedAt,
                    deleted_at: deletedAt
                };
                let newData = [];

                for (const lesson of student.lessonsProgram) {
                    if (+lesson.id === +res.data.id) {
                        newData = [...newData, resultUT];
                    } else {
                        newData = [...newData, lesson];
                    }
                }

                dispatch(updateStudent({
                    "lessonsProgram": newData
                }));

            } else {

                try {
                    const newStudentLevel = +student.englishLevel + 1;
                    const studentRes = await axios.patch(Config.BACKEND_ADDR + `/students/`, {
                        student_id: student.studentId,
                        english_skill: "" + newStudentLevel
                    });

                    const filteredLevels = levels.filter(level => +level.name === +newStudentLevel);
                    const materials = filteredLevels[0].materials;

                    if (materials) {

                        dispatch(updateStudent({
                            lessonsProgram: []
                        }));
                        let nextLessonProgram = [];
                        for (const material of materials) {
                            try {
                                const response = await axios.post(Config.BACKEND_ADDR + "/program/newStep/", {
                                    student_id: student.studentId,
                                    level_id: newStudentLevel,
                                    material_id: material.id,
                                });

                                let createdAt = new Date(response.data.created_at).getTime();
                                let updatedAt = new Date(response.data.updated_at).getTime();
                                let deletedAt = response.data.deleted_at ? new Date(response.data.deleted_at).getTime() : null;

                                const newData = {
                                    ...response.data,
                                    created_at: createdAt,
                                    updated_at: updatedAt,
                                    deleted_at: deletedAt
                                };

                                nextLessonProgram = [...nextLessonProgram, newData];
                            } catch (err) {
                                console.warn(err);
                            }
                        }
                        dispatch(updateStudent({
                            lessonsProgram: nextLessonProgram,
                            englishLevel: "" + newStudentLevel
                        }));
                    }

                } catch (err) {
                    console.warn(err);
                }
            }
        } catch (err) {
            console.warn(err);
        }
    }, [lessonData.id, lessonData.student_id, lessonData.level_id, lessonData.material_id, lessonData.is_done, dispatch, isLast, student.lessonsProgram, student.studentId, student.englishLevel]);




    return (
        <>
        {isModalQuestionOpen &&
            <ModalQuestion
                isOpen={isModalQuestionOpen}
                setIsOpen={setIsModalQuestionOpen}
                type={"error"}
                h2={"Удалить занятие?"}
                p={"Отменить действие будет невозможно"}
                onClose={handleDelete}
            />
        }
        <div style={{
            width: 534,
            display: "flex",
            flexDirection: "row",
            gap: 10,
            filter: (!lessonData.is_done && !previousIsDone) ? "grayscale(100%)" : "none",
            border: (!lessonData.is_done && previousIsDone) ? "2px solid red" : "none",
            borderRadius: "20px",
            padding: 8,

        }}>
            <img src={Config.CLOUD_ADDR + '/speakid/' + lessonData.image_path} alt={"lessonImag"} style={{
                width: 176,
                height: 104,
                borderRadius: 20
            }} />
            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between"
            }}>
                <StudentLessonText index={lessonData.index} title={lessonData.name} level={"Level: " + lessonData.level_id} />
                <div style={{
                    width: 28,
                    height: 28,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    backgroundColor: lessonData.is_done ? color_green_light : color_grey_ultra_light
                }}>
                    {!lessonData.is_done && !previousIsDone ?
                        <LuLoader
                            style={{ color: color_grey_light }}
                            onClick={handleToggleComplete}
                        />
                        :
                        <BsCheck2
                            style={{ color: lessonData.is_done ? color_green : color_grey_light }}
                            onClick={handleToggleComplete}
                        />
                    }
                </div>
            </div>
            <div style={{ flexGrow: 1 }} />

            <div style={{ cursor: "pointer" }} onClick={handleOpenQuestion}>
                <BsXLg />
            </div>
        </div>
    </>
    )
}

export const StudentLessonsProgram = ({ levels }) => {
    const navigate = useNavigate();
    
    const student = useSelector(state => state.student);
    const dispatch = useDispatch();
    const [program, setProgram] = useState([])

    useEffect(() => {
        axios.get(Config.BACKEND_ADDR + `/program/level/`, {
            params: {
                student_id: student.studentId,
                level_name: student.englishLevel,
            }
        })
            .then(res => {
                let newData = res.data.map(step => {
                    const createdAt = new Date(step.created_at).getTime();
                    const updatedAt = new Date(step.updated_at).getTime();
                    const deletedAt = step.deleted_at ? new Date(step.deleted_at).getTime() : null;
                    return {
                        ...step,
                        created_at: createdAt,
                        updated_at: updatedAt,
                        deleted_at: deletedAt
                    };
                });
                dispatch(updateStudent({
                    "lessonsProgram": newData
                }))
            })
            .catch(err => { console.warn(err) })
    }, [student.studentId, student.englishLevel, dispatch])

    useEffect(() => {
        let newProgram = [];
        let materials = []
        for (const level of levels) {
            if (+level.name === +student.englishLevel) {
                materials = [...level.materials]
            }
        }


        if (materials.length > 0) {

            student.lessonsProgram.forEach(lesson => {
                const material = materials.find(mat => mat.id === lesson.material_id);
                let materialData = {};
                if (material) {
                    materialData = { ...material, ...lesson };
                }
                newProgram = [...newProgram, materialData];
            });
        }
        newProgram.sort((a, b) => a.index - b.index);
        setProgram(newProgram);
    }, [student.lessonsProgram, levels, student.englishLevel, dispatch])

    const programElements = useMemo(() => {

        let previousIsDone = true
        return program.map(el => {
            let newIsLast = el.id === program[program.length - 1].id;
            const element =
                el.is_deleted ?
                    <></>
                    :
                    (<StudentLesson
                        key={el.id}
                        lessonData={el}
                        levels={levels}
                        previousIsDone={previousIsDone}
                        isLast={newIsLast}
                    />);


            previousIsDone = el.is_done;
            return element;
        });
    }, [program]);

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            width: 600,
            minHeight: 450,
            borderRadius: 20,
            padding: 25,
            backgroundColor: color_white,
            boxSizing: "border-box",
            gap: 20,
            overflow: "scroll"
        }}>
            <div style={{
                display: "flex",
                flexDirection: "row"
            }}>
                <BlockTitle title={"Программа занятий"} />
                
                <Button
                    active={true} 
                    backgroundColor={color_transparent}
                    borderColor={color_grey_light}
                    width={170}
                    height={25}
                    fontSize={12}
                    outline={false}
                    color={color_grey_dark}
                    onClick={() => {
                        navigate('/service/program/');
                    }}
                >Вся программа</Button>
                 
            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                gap: 10
            }}>
                {programElements}
            </div>
        </div>
    )
}