import { color_black, color_grey_dark, color_grey_light, color_grey_ultra_light, color_transparent, color_white } from "../../constants/colors";
import { Button } from "../controls/Button/Button";
import {OnestNormalBig, OnestNormalDefault} from "../styled/TextComponents";
import { BlockTitle } from "../text/BlockTitle";
import {useDispatch, useSelector} from "react-redux";
import { useNavigate } from 'react-router-dom';

async function getStudentData(studentId) {
    await new Promise(r => setTimeout(r, 1500));
    return {
        "studentId": studentId,
        "lessonPrice": 20,
        "studentBalance": 200,
        "payedLessons": parseInt(201/20),
        "name": "Миша Смирнов",
        "englishLevel": 1,
        "additionalInfo": "Миша - “технарь”, ему сложно дается изучение языков. Английский учит для переезда за границу. Необходимо дать понимание базовых вещей, чтобы он мог влиться в общество.",
        "lessonsSchedule": [
            {
                weekDay: "Вторник",
                time: "12:00 - 13:00"
            },
            {
                weekDay: "Четверг",
                time: "15:30 - 16:30"
            }
        ]
    }
}

export const StudentScheduleBlock = ({text}) => {
    return (
        <div style={{
            //width: 150,
            flex: 1,
            height: 40,
            borderRadius: 10,
            backgroundColor: color_grey_ultra_light,
            color: color_black,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 10,
            padding: 4,

        }}> 
            <OnestNormalDefault>{text}</OnestNormalDefault>
        </div>
    )
}

export const StudentSchedule = () => {
                        const navigate = useNavigate();
    const student = useSelector(state=>state.student);
    const dispatch = useDispatch();

    return(
        <div style={{
            display: "flex",
            flexDirection: "column",
            width: 600,
            // minHeight: 191,
            borderRadius: 20,
            padding: 25,
            backgroundColor: color_white,
            boxSizing: "border-box",
            gap: 20,
            // overflow: "scroll"
        }}>
            <div style={{
                display: "flex",
                flexDirection: "row"
            }}>
                <BlockTitle title={"Расписание занятий"}/>
                <Button 
                    active={true}
                    backgroundColor={color_transparent}
                    borderColor={color_grey_light}
                    width={170}
                    height={25}
                    fontSize={12}
                    color={color_grey_dark}
                    onClick={() => {
                        navigate('/service/schedule/');
                    }}
                >Открыть календарь</Button>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                gap: 10
            }}>
                {student.lessonsSchedule.map(el => {
                    return (
                        <div
                         key={el.id}
                         style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 10
                        }}>
                            <StudentScheduleBlock text={el.weekDay}/>
                            <StudentScheduleBlock text={el.time}/>
                        </div>
                    )
                })
                }
                {
                    student.lessonsSchedule.length === 0 ?
                        <OnestNormalBig>Расписание не назначено</OnestNormalBig>
                        :
                        null
                }
            </div>
        </div>
    )
}